<template>
  <div class="container-tabs">
    <div class="d-flex">
      <div
        v-for="(i, index) in items"
        :key="index"
        class="tab"
        :ref="i.value"
        @click="goNext(i.value, index)"
      >
        <div :class="active == i.value ? 'active' : ''">{{ i.text }}</div>
      </div>
    </div>
    <div ref="tabActive" class="tab-active-block"></div>
  </div>
</template>
<script>
export default {
  props: {
    value: {},
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: () => ({
    active: null,
    number: 0,
  }),
  methods: {
    goNext(value, index) {
      if (this.active) {
        index > this.number
          ? this.$emit("click", false)
          : this.$emit("click", true);
      }
      this.active = value;
      this.number = index;
      this.$emit("input", value);
      const el = this.$refs[value];
      if (el[0]) {
        this.$refs.tabActive.style.left = `${el[0].offsetLeft + 2}px`;
        this.$refs.tabActive.style.width = `${el[0].offsetWidth - 4}px`;
      }
    },
  },
  computed: {},
  mounted() {
    this.goNext(this.value);
  },
  created() {},
  watch: {},
};
</script>
<style scoped lang="scss">
.container-tabs {
  display: flex;
  position: relative;
  .tab-active-block {
    position: absolute;
    z-index: 1;
    bottom: 0px;
    left: 2px;
    width: 0px;
    bottom: 2px;
    height: 30px;
    background: var(--app-blue);
    opacity: 0.2;
    transition: 0.3s;
    cursor: pointer;
    border-radius: 6px;
  }
  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    min-width: 135px;
    background: var(--app-white);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
    color: var(--app-grey-3);
  }
  .tab:last-child {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
  .tab:first-child {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }

  .active {
    transition: 0.5s;
    color: var(--app-blue);
  }
}
</style>
