<template>
  <div class="block-news-news-setting" v-if="loading">
    <div class="setting-block">
      <div class="setting-block-box">
        <div class="block-box-title">
          <div class="box-title-text">Информация</div>
        </div>
        <v-form ref="formInformation" class="block-box-news">
          <ImageInput v-model="news.photo" label="Обложка новости" />
          <TextInput
            :maxLength="300"
            :max="300"
            v-model="news.title"
            label="Название новости"
            valid
          />
          <TextArea
            :maxLength="4000"
            :max="4000"
            v-model="news.description"
            label="О новости"
          />
          <div class="block-box-title">
            <div class="box-title-text">Топики</div>
            <v-btn
              :disabled="true"
              depressed
              class="button-white-blue"
              @click="openDialogTag"
              >Добавить топик</v-btn
            >
          </div>

          <div class="d-flex" v-for="(tag, index) in news.topics" :key="index">
            <SelectInput :label="tag.name" itemValue="id" :clearable="false" />
            <div
              class="ml-4 mt-4"
              style="cursor: pointer"
              @click="deleteTag(tag)"
            >
              <v-icon>mdi-close</v-icon>
            </div>
          </div>
          <!-- stub -->
          <div class="publication-info">
            <SwitchInput icon="mdi-eye-outline" label="Видимость новости" />
            <SwitchInput
              icon="mdi-bullhorn"
              label="Отправить пуш при публикации новости"
            />
            <div class="info-text">
              <v-icon class="mr-2" color="#BEBEC0" size="20"
                >mdi-information</v-icon
              >
              Отправка пуш-уведомления возможна 1 раз. Для повторной отправки
              необходимо создать новость заново
            </div>
          </div>
          <!-- stub -->
        </v-form>
      </div>
    </div>
    <div class="setting-block">
      <div class="setting-block-box">
        <div class="block-box-title">
          <div class="box-title-text">Данные</div>
        </div>
        <div class="block-box-news">
          <div class="box-news-info">
            <div class="info-title">ID новости</div>
            <div class="info-value">
              {{ news.id }}
            </div>
          </div>
          <div class="box-news-info">
            <div class="info-title">Дата создания</div>
            <div class="info-value">
              {{
                this.$moment(parseInt(news.dateCreated)).format("DD.MM.YYYY")
              }}
            </div>
          </div>
          <div class="box-news-info">
            <div class="info-title">Дата последнего редактирования</div>
            <div class="info-value">
              {{
                this.$moment(parseInt(news.dateUpdated)).format("DD.MM.YYYY")
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog
      v-model="dialogAddTag"
      titleText="Выберите топики который вы хотите добавить новости"
    >
      <template v-slot:blockMain>
        <div class="dialog-tag-table">
          <Table :items="tagsNotUsed" :headers="tagsHeaders" headerFixed>
            <template v-slot:[`item.tagType`]>
              <div>Топик</div>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <CheckBox
                v-model="item.selected"
                color="var(--app-blue)"
                @change="selectedTagMethod($event, item)"
              ></CheckBox>
            </template>
          </Table>
        </div>
      </template>
      <template v-slot:blockFooter>
        <div class="d-flex justify-end" style="width: 100%">
          <v-btn
            @click="addNewsTag"
            :disabled="selectedTags.length === 0"
            depressed
            class="button-blue"
          >
            Добавить
          </v-btn>
        </div>
      </template>
    </Dialog>
  </div>
</template>
<script>
import ImageInput from "@/components/main/inputs/ImageInput";
import TextInput from "@/components/main/inputs/TextInput";
import TextArea from "@/components/main/inputs/TextArea";
import CheckBox from "../../components/main/inputs/CheckBox.vue";
import SwitchInput from "@/components/main/inputs/SwitchInput";

export default {
  components: {
    ImageInput,
    TextInput,
    TextArea,
    CheckBox,
    SwitchInput,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: () => ({
    loading: false,
    news: {},
    dialogAddTag: false,
    tagsNews: [],
    tagsNotUsed: [],
    selectedTags: [],
    tagsHeaders: [
      {
        text: "",
        value: "action",
      },
      {
        text: "Название тега",
        value: "name",
      },
      {
        text: "Тип тега",
        value: "tagType",
      },
    ],
  }),
  methods: {
    updateItem() {
      this.news = JSON.parse(JSON.stringify(this.item));

      this.loading = true;
    },
    sendItem() {
      if (this.$refs.formInformation.validate()) {
        return this.news;
      }
    },
    setTags() {
      this.topics.forEach((i) => {
        this.news.topics.push(i);
      });

      this.findNeededTags();
    },
    async getTags() {
      await this.$axios
        .get(this.$store.getters.apiAdminPanelV4 + "/tag/list", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          this.topicsNews = res.data;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    findNeededTags() {
      this.topicsNotUsed = [];
      this.topicsNews.forEach((element) => {
        if (this.topicsNews.find((i) => i.id == element.id)) {
          this.topicsNotUsed.push({ ...element, selected: false });
        }
      });
    },
    selectedTagMethod(bool, item) {
      if (bool) {
        this.selectedTags.push({ ...item });
      } else {
        this.selectedTags = this.selectedTags.filter((i) => i.id != item.id);
      }
    },
    openDialogTag() {
      this.findNeededTags();
      this.selectedTags = [];
      this.dialogAddTag = true;
    },

    addNewsTag() {
      this.selectedTags.forEach((element) => {
        this.news.topics.push(element.id);
      });
      this.dialogAddTag = false;
      this.findNeededTags();
    },
    deleteTag(tag) {
      this.topicsNews = this.topicsNews.filter((i) => i.id != tag.id);
      this.findNeededTags();
    },
  },
  mounted() {},
  created() {
    this.updateItem();
    this.getTags();
  },
  beforeDestroy() {},
  watch: {
    item() {
      this.updateItem();
    },
  },
};
</script>
<style lang="scss" scoped>
.block-news-news-setting {
  background: var(--app-grey-1);
  //   overflow: auto;
  height: 100%;
  padding: 32px 64px;
  display: flex;
  justify-content: space-between;
  .setting-block {
    .setting-block-box {
      .block-box-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .box-title-text {
          font-size: 22px;
          font-weight: 600;
          color: var(--app-black);
        }
      }
      .block-box-news {
        .publication-info {
          & ::v-deep .text-box-text {
            font-weight: 400 !important;
          }
        }
        .info-text {
          color: #bebec0;
        }
        .box-items-list-item {
          width: 100%;
          min-height: 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: var(--app-white);
          border-radius: 6px;
          margin-bottom: 20px;
          padding: 16px 12px;
          .list-item-text {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: var(--app-black);
          }
          .list-item-icon {
            cursor: pointer;
          }
        }
        .box-news-info {
          margin-bottom: 24px;
          .info-title {
            font-size: 12px;
            text-transform: uppercase;
            color: var(--app-grey-3);
          }
          .info-value {
            font-size: 14px;
            color: var(--app-black);
          }
        }
      }
    }
  }
  .setting-block:first-child {
    width: 60%;
    padding-right: 48px;
  }
  .setting-block:last-child {
    width: 40%;
    padding-left: 48px;
  }
  .dialog-tag-table {
    height: 400px;
  }
}
</style>
