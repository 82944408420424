<template>
  <Dialog v-model="dialog" :width="510">
    <template v-slot:blockTitleText>
      {{ titleText }}
    </template>
    <template v-slot:blockMain>
      {{ mainText }}
    </template>
    <template v-slot:blockFooter>
      <v-btn depressed class="button-white-blue" @click="closeDialog">
        Отменить
      </v-btn>
      <v-btn depressed class="button-red" @click="deleteMethod">
        Удалить
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
export default {
  props: {
    value: { type: Boolean, default: false },
    titleText: { type: String, default: "" },
    mainText: { type: String, default: "" },
  },
  methods: {
    closeDialog() {
      this.$emit("input", false);
    },
    deleteMethod() {
      this.$emit("click:delete");
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    },
  },
};
</script>